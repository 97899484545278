@tailwind base;
@tailwind components;
@tailwind utilities;

@import "_buttons";

body {
  @apply mb-10;
}

.urlize a,
nav a {
  @apply text-blue-400 no-underline;

  &:hover,
  &:focus {
    @apply underline;
  }
}

.rating__form p {
  @apply my-8;
}

.rating__form p label {
  @apply font-bold block mb-2;
}

.rating__form .helptext {
  @apply block mb-4;
}

.rating__button {
  @apply text-lg w-15 h-15 border;

  &:last-child {
    @apply ml-4;
  }

  &.active {
    @apply bg-gray-700 text-white font-bold;
  }
}

.rating__form [name="comment"] {
  @apply border p-2;
}

.rating__form p label[for="id_is_draft"],
.rating__form p label[for="id_is_conflict"] {
  @apply inline-block;
}

.rating__form [type="checkbox"] {
  @apply inline-block ml-2 w-4 h-4;
}

.table__stats td,
.table__stats th {
  @apply px-4 py-2;
}

.reviewers-list {
  column-count: 3;
  column-gap: 2em;
}
