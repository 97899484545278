.button.primary {
  @apply inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5;
  @apply font-medium rounded-md text-white bg-indigo-500 transition duration-150 ease-in-out;

  &:hover {
    @apply bg-indigo-400 cursor-pointer;
  }

  &:focus {
    @apply shadow-outline-indigo outline-none border-indigo-700;
  }

  &:active {
    @apply bg-indigo-700;
  }
}

.button.secondary {
  @apply inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5;
  @apply font-medium rounded-md text-white transition duration-150 ease-in-out bg-green-400 text-white;

  &:hover {
    @apply bg-green-200 cursor-pointer text-gray-500;
  }

  &:focus {
    @apply shadow-outline-indigo outline-none border-green-900;
  }

  &:active {
    @apply bg-green-800;
  }
}
